import React, { useState, useRef, useEffect } from "react";
import mainBlue from "../../assets/images/main-blue.svg";
import mainLightblue from "../../assets/images/main-lightblue.png";
import mainOrange from "../../assets/images/main-orange.svg";
import mainYellow from "../../assets/images/main-yellow.svg";
import image01 from "../../assets/images/01.png";
import image02 from "../../assets/images/02.png";
import image03 from "../../assets/images/03.png";
import { ReactComponent as IconSearch } from "../../assets/images/icon-search.svg";
import { ReactComponent as ArrowScroll } from "../../assets/images/arrow-scroll.svg";
import Navbar from "../../components/Navbar";
import Fade from "react-reveal/Fade";
import Slide from "react-reveal/Slide";

export default function HeaderHome() {
  const ref = useRef();
  const [search, setSearch] = useState("");
  const [searchValue, setSearchValue] = useState("");
  const [searchFilter, setSearchFilter] = useState("");
  const [showSearchRequest, setShowSearchRequest] = useState(false);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setShowSearchRequest(false);
        } else {
          setShowSearchRequest(true);
        }
      },
      {
        root: null,
        rootMargin: "0px",
        threshold: 0.1,
      }
    );
    if (ref.current) {
      observer.observe(ref.current);
    }
  }, [ref]);

  useEffect(() => {
    if (search.search("camarones") === 0) {
      setSearchFilter("Camarones");
    }
    if (search.search("pescado") === 0) {
      setSearchFilter("Pescado");
    }
  }, [search]);

  const keyPressHandler = (event) => {
    if (event.key === "Enter") {
      setSearchValue(search);
    }
  };

  return (
    <>
      <Navbar
        setSearchText={setSearchValue}
        searchText={searchValue}
        showSearchRequest={showSearchRequest}
      />
      <div
        className={`hidden h-header 2xl:h-headerxl bg-white relative overflow-hidden lg:flex justify-center mt-24 ${
          search ? "" : ""
        }`}
      >
        <img
          src={mainLightblue}
          alt=""
          className="absolute right-0 top-0 h-full pb-12 2xl:pb-32 w-11/12"
        />
        <img
          src={mainYellow}
          alt=""
          className="absolute right-0 bottom-0 h-40per w-64 object-cover object-top 2xl:object-left"
        />
        <div className="absolute right-0 top-0 h-60per w-90 2xl:object-left">
          <img
            src={mainBlue}
            alt=""
            className=" h-full object-cover object-bottom 2xl:object-left w-full"
          />
          <Slide right delay={1000}>
            <div className="absolute left-0 bottom-0 w-10/12 2xl:w-full -mb-10 -ml-10 2xl:-ml-20">
              <img src={image02} alt="" className="" />
            </div>
          </Slide>
        </div>
        <div className="absolute bottom-0 left-0 h-60per w-90 2xl:w-100">
          <img src={mainOrange} alt="" className="" />
          <Slide left delay={300} appear>
            <img
              src={image01}
              alt=""
              className="absolute right-0 top-0 2xl:-mr-20 -mt-20 w-64 2xl:w-90"
            />
          </Slide>
        </div>
        <div className=" relative flex flex-col items-center mt-20 2xl:mt-32">
          <Fade bottom duration={1000} distance={"25%"}>
            <h4 className=" text-white text-5xl 2xl:text-6xl text-center leading-tight font-bold">
              ¿Qué tienes ganas <br />
              de cocinar hoy?
            </h4>
          </Fade>
          <div
            ref={ref}
            className="mt-8 flex items-center bg-white rounded-full overflow-hidden px-4 w-100 shadow-lg"
          >
            <Fade bottom duration={1000} delay={400} distance={"25%"}>
              <span>
                <IconSearch className="h-5 mr-3" />
              </span>
              <input
                type="search"
                onChange={(e) => setSearch(e.target.value)}
                onKeyPress={(e) => keyPressHandler(e)}
                value={search}
                className="appearance-none focus:outline-none placeholder-grayText font-medium text-blueText w-full py-3 "
                placeholder="Buscar recetas..."
              />
            </Fade>
          </div>
          <div
            className={`mt-5 felx justify-center transition duration-200 ease-in-out hidden ${
              search ? " opacity-100" : " opacity-0"
            }`}
          >
            <span className=" text-white font-medium mr-5">Filtrar:</span>
            <div
              onClick={() => setSearchFilter("Camarones")}
              className={` ${
                searchFilter === "Camarones" ? " bg-blue" : " border-white"
              }  inline-block border-2 border-white text-white mr-5 rounded-full px-5 py-2 cursor-pointer`}
            >
              Camarones
            </div>
            <div
              onClick={() => setSearchFilter("Pescado")}
              className={` ${
                searchFilter === "Pescado" ? " bg-blue" : " border-white"
              } inline-block border-2 border-white text-white mr-5 rounded-full px-5 py-2 cursor-pointer`}
            >
              Pescado
            </div>
          </div>
        </div>

        <div className="absolute bottom-0 flex flex-col items-center text-blueText">
          <Slide bottom delay={1500}>
            <div className="absolute right-0 bottom-0 w-48 2xl:w-64 -mr-64 2xl:-mr-100 -mb-20">
              <img src={image03} alt="" className=" w-full h-full" />
            </div>
          </Slide>
          <Fade bottom duration={1000} delay={2000} distance={"25%"}>
            <p className=" text-blueText font-brandom text-xl font-bold">
              Explorar recetas
            </p>
            <ArrowScroll className=" w-4 mt-4 animate-bounce fill-current" />
          </Fade>
        </div>
      </div>

      <div className="lg:hidden mt-24">
        <div className=" h-headerMobile bg-lightBlue relative overflow-hidden">
          <div className="absolute bottom-0 left-0 w-56">
            <img src={mainOrange} alt="" className=" -mb-5" />
            <Slide left delay={500}>
              <img
                src={image01}
                alt=""
                className="absolute right-0 top-0 -mr-10 -mt-10 w-48"
              />
            </Slide>
          </div>

          <div
            className={`absolute right-0 bottom-0 mb-32 w-48 -mr-24 transition duration-200 ease-in-out ${
              search ? " opacity-0" : " opacity-100"
            }`}
          >
            <Slide right delay={1500}>
              <img src={image03} alt="" className="" />
            </Slide>
          </div>

          <div className="relative flex flex-col items-center px-5 pt-10 md:pt-20">
            <Fade bottom duration={1000} delay={250} distance={"25%"}>
              <h4 className=" text-white text-4xl text-center leading-tight font-bold -mx-5">
                ¿Qué tienes ganas <br className="block" />
                de cocinar hoy?
              </h4>
            </Fade>
            <Fade bottom duration={1000} delay={250} distance={"25%"}>
              <div className="mt-8 flex items-center bg-white rounded-full overflow-hidden px-4 w-full shadow-lg">
                <span>
                  <IconSearch className="h-5 mr-3" />
                </span>
                <input
                  type="search"
                  onChange={(e) => setSearch(e.target.value)}
                  value={search}
                  onKeyPress={(e) => keyPressHandler(e)}
                  className="appearance-none focus:outline-none placeholder-grayText font-medium text-blueText w-full py-3 "
                  placeholder="Buscar recetas..."
                />
              </div>
            </Fade>
            <div
              className={`mt-5 felx justify-center transition duration-200 ease-in-out hidden ${
                search ? " opacity-100" : " opacity-0"
              }`}
            >
              <span className=" text-white font-medium mr-2 lg:mr-5">
                Filtrar:
              </span>
              <div
                onClick={() => setSearchFilter("Camarones")}
                className={` ${
                  searchFilter === "Camarones" ? " bg-blue" : " border-white"
                } inline-block border-2 text-white mr-2 lg:mr-5 rounded-full px-3 py-2 cursor-pointer text-sm`}
              >
                Camarones
              </div>
              <div
                onClick={() => setSearchFilter("Pescado")}
                className={` ${
                  searchFilter === "Pescado" ? " bg-blue" : " border-white"
                } inline-block border-2 text-white mr-2 lg:mr-5 rounded-full px-3 py-2 cursor-pointer text-sm`}
              >
                Pescado
              </div>
            </div>
          </div>
        </div>
        <div className=" flex flex-col mt-5 items-center justify-center text-blueText">
          <p className=" text-blueText font-brandom text-xl font-bold">
            Explorar recetas
          </p>
          <ArrowScroll className=" w-3 mt-4 animate-bounce fill-current" />
        </div>
      </div>
    </>
  );
}
