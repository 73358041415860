import React from "react";
import mainBlue from "../../assets/images/profile-blue.svg";
import mainBeige from "../../assets/images/profile-beige.svg";
import { ReactComponent as ArrowScroll } from "../../assets/images/arrow-scroll.svg";
import Fade from "react-reveal/Fade";

export default function HeaderProfile({ recipe }) {
  return (
    <>
      <div className="hidden h-header 2xl:h-headerxl bg-white relative overflow-hidden lg:flex justify-center mt-24">
        <img src={mainBlue} alt="" className="absolute right-0 top-0 w-100" />

        <img
          src={mainBeige}
          alt=""
          className="absolute left-0 bottom-0 w-100"
        />

        <div className="absolute container h-full w-full">
          <div className="rounded-bl-xlarge h-full w-full overflow-hidden relative">
            <Fade duration={1000} delay={1500}>
              <div className="w-full h-full">
                <img
                  src={recipe.coverIMG}
                  alt=""
                  className="w-full h-full object-cover"
                />
              </div>
            </Fade>
            <div className="absolute inset-0 bg-black opacity-25"></div>
          </div>
        </div>

        <div className=" relative flex items-center justify-center">
          <Fade bottom duration={1000} delay={2000} distance={"25%"}>
            <h4 className=" text-white text-3xl text-center leading-tight font-bold">
              {recipe.name}
            </h4>
          </Fade>
        </div>
        <div className="absolute bottom-0 flex flex-col items-center mb-10 text-white">
          <Fade duration={1000} delay={2000}>
            <ArrowScroll className=" w-4 animate-bounce fill-current" />
          </Fade>
        </div>
      </div>

      <div className="lg:hidden h-100 relative mt-24">
        <div className="absolute h-full w-full">
          <div className="rounded-bl-xlarge h-full w-full overflow-hidden relative">
            <Fade duration={1000} delay={1500}>
              <div className="w-full h-full">
                <img
                  src={recipe.coverIMG}
                  alt=""
                  className="w-full h-full object-cover"
                />
              </div>
            </Fade>
            <div className="absolute inset-0 bg-black opacity-25"></div>
          </div>
        </div>

        <div className="relative flex h-full items-center justify-center px-5">
          <Fade bottom duration={1000} delay={2000} distance={"25%"}>
            <h4 className="text-white text-2xl text-center leading-tight font-bold">
              {recipe.name}
            </h4>
          </Fade>
        </div>
      </div>
    </>
  );
}
