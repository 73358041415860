import React, { useEffect, useState, useRef } from "react";
import Navbar from "../../components/Navbar";
import HeaderProfile from "./HeaderProfile";
import { ReactComponent as IconClock } from "../../assets/images/icon-clock.svg";
import { ReactComponent as IconCutlery } from "../../assets/images/icon-cutlery.svg";
import Ingredients from "./Ingredients";
import Footer from "../../components/Footer";
import Process from "./Process";
import Familyrecipes from "./Familyrecipes";
import NewsLetters from "../../components/NewsLetters";
import { useParams } from "react-router-dom";
import wa from "../../assets/images/wa-m.svg";
import fb from "../../assets/images/fb-m.svg";
import tw from "../../assets/images/tw-m.svg";
import lin from "../../assets/images/in-m.svg";
import mainBeige from "../../assets/images/profile-beige.svg";
import share from "../../assets/images/share-m.svg";
import Fade from "react-reveal/Fade";
import { CopyToClipboard } from "react-copy-to-clipboard";
import axios from "axios";

export default function RecipeProfile() {
  const ref = useRef();
  const [recipe, setRecipe] = useState({});
  const [showNameinTop, setShowNameinTop] = useState(false);
  const { recipeId } = useParams();

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_API_BASE_URL}/recipes/${recipeId}`)
      .then((res) => {
        setRecipe(res.data || {});
      })
      .catch((error) => {
        throw error;
      });
  }, [recipeId]);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setShowNameinTop(false);
        } else {
          setShowNameinTop(true);
        }
      },
      {
        root: null,
        rootMargin: "0px",
        threshold: 0.1,
      }
    );
    if (ref.current) {
      observer.observe(ref.current);
    }
  }, [ref]);

  const getLevel = (level) => {
    switch (level) {
      case 0:
        return (
          <>
            <p className="text-grayText font-medium text-lg">
              Dificultad: <span className=" text-green">Fácil</span>
            </p>
            <div className="mt-5 space-x-2 flex">
              <div className=" rounded-full border-green border-2 w-20 h-2 bg-green "></div>
              <div className=" rounded-full border-grayText border-2 w-20 h-2 bg-grayText"></div>
              <div className=" rounded-full border-grayText border-2 w-20 h-2 bg-grayText "></div>
            </div>
          </>
        );
      case 1:
        return (
          <>
            <p className="text-grayText font-medium text-lg">
              Dificultad: <span className=" text-yellow">Medio</span>
            </p>
            <div className="mt-5 space-x-2 flex">
              <div className=" rounded-full border-yellow border-2 w-20 h-2 bg-yellow "></div>
              <div className=" rounded-full border-yellow border-2 w-20 h-2 bg-yellow"></div>
              <div className=" rounded-full border-grayText border-2 w-20 h-2 bg-grayText "></div>
            </div>
          </>
        );
      case 2:
        return (
          <>
            <p className="text-grayText font-medium text-lg">
              Dificultad: <span className=" text-orange">Avanzado</span>
            </p>
            <div className="mt-5 space-x-2 flex">
              <div className=" rounded-full border-orange border-2 w-20 h-2 bg-orange "></div>
              <div className=" rounded-full border-orange border-2 w-20 h-2 bg-orange"></div>
              <div className=" rounded-full border-orange border-2 w-20 h-2 bg-orange "></div>
            </div>
          </>
        );
      default:
        return null;
    }
  };

  return (
    <div className="overflow-hidden">
      <Navbar profile />

      {!showNameinTop && (
        <div className="fixed z-50 h-32 bg-white w-full items-center justify-center hidden lg:flex">
          <div className="absolute left-0 bottom-0 w-100">
            <img src={mainBeige} alt="" className=" w-full" />
          </div>
          <div className="absolute container h-full w-full">
            <div className="rounded-bl-xlarge h-full w-full relative overflow-hidden">
              <img
                src={recipe.coverIMG}
                alt=""
                className="w-full h-full object-cover object-left"
              />
              <div className="absolute inset-0 bg-black opacity-25"></div>
            </div>
          </div>
          <Fade bottom duration={1000} distance={"25%"}>
            <p className=" font-bold text-white text-2xl text-center">
              {recipe.name}
            </p>
          </Fade>
        </div>
      )}

      {!showNameinTop && (
        <div className="fixed z-50 h-24 bg-white w-full flex items-center justify-center lg:hidden">
          <Fade bottom duration={1000} delay={500} distance={"25%"}>
            <p className=" font-bold text-blueText text-2xl text-center">
              {recipe.name}
            </p>
          </Fade>
        </div>
      )}

      {!showNameinTop && (
        <div className="fixed bottom-0 z-50 h-16 bg-blue w-full flex items-center justify-center lg:hidden">
          <Fade duration={1000} delay={500}>
            <ul className=" flex items-center space-x-5">
              <li>
                <a
                  href={`http://www.facebook.com/share.php?u=${window.location}`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img src={fb} alt="" className="h-8" />
                </a>
              </li>
              <li>
                <a
                  href={`https://twitter.com/intent/tweet?text=${window.location}`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img src={tw} alt="" className="h-8" />
                </a>
              </li>
              <li>
                <a
                  href={`https://www.linkedin.com/shareArticle?mini=true&url=${window.location}&title=&summary=&source=`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img src={lin} alt="" className="h-8" />
                </a>
              </li>
              <li>
                <a href={`whatsapp://send?text=${window.location}`}>
                  <img src={wa} alt="" className="h-8" />
                </a>
              </li>
              <li>
                <CopyToClipboard
                  text={window.location}
                  onCopy={() => alert("Enlace copiado al portapapeles")}
                >
                  <span>
                    <img src={share} alt="" className="h-10 cursor-pointer" />
                  </span>
                </CopyToClipboard>
              </li>
            </ul>
          </Fade>
        </div>
      )}

      <HeaderProfile recipe={recipe} />

      <div className="container">
        <div className="flex flex-col lg:flex-row mt-10">
          <Fade bottom duration={1000} delay={1000} distance={"25%"}>
            <div className="bg-lightOrange w-full lg:w-4/6 py-12 flex flex-col lg:flex-row lg:items-center px-5">
              <div className="lg:w-1/2 lg:pl-10">
                {getLevel(recipe ? Number(recipe.level) : 0)}
              </div>
              <div className="lg:w-1/2 flex flex-col lg:items-center mt-8 lg:mt-0">
                <p className=" text-blueText uppercase font-bold">
                  Tiempo y Porciones
                </p>
                <div className="flex items-center mt-3 lg:mt-5">
                  <div className="flex items-center text-grayText text-sm">
                    <IconClock className=" h-4 mr-2" />
                    {recipe.time}
                  </div>
                  <div className="h-1 w-1 rounded-full bg-blue mx-3"></div>
                  <div className="flex items-center text-grayText text-sm">
                    <IconCutlery className=" h-4 mr-2" />
                    {recipe.portionSize}
                  </div>
                </div>
              </div>
            </div>
          </Fade>
          <Fade bottom duration={1000} delay={500} distance={"25%"}>
            <div className="lg:w-2/6 flex justify-center mt-10 lg:mt-0">
              <div className="h-56">
                <img
                  src={recipe.productIMG}
                  alt=""
                  className="h-full object-cover"
                />
              </div>
            </div>
          </Fade>
        </div>

        <div ref={ref} className="">
          <Ingredients
            tip={recipe.tip}
            insgredients={recipe && recipe.ingredients}
          />

          <Process process={recipe.process} />

          <Familyrecipes level={recipe.level} />
        </div>
      </div>
      <NewsLetters />
      <Footer />
    </div>
  );
}
