import React from "react";
import { ReactComponent as IconClock } from "../../assets/images/icon-clock.svg";
import { ReactComponent as IconCutlery } from "../../assets/images/icon-cutlery.svg";
import { ReactComponent as IconBooklist } from "../../assets/images/icon-booklist.svg";
import { Link } from "react-router-dom";

export default function CardRecipe({ value, dragging }) {
  const getLevelTag = (level) => {
    switch (level) {
      case "0":
        return (
          <div className="absolute right-0 bottom-0 mb-3 mr-3 bg-green px-6 rounded-r-full rounded-bl-full py-1 text-greenStrong text-sm font-bold">
            Fácil
          </div>
        );
      case "1":
        return (
          <div className="absolute right-0 bottom-0 mb-3 mr-3 bg-yellow px-6 rounded-r-full rounded-bl-full py-1 text-yellowStrong text-sm font-bold">
            Medio
          </div>
        );
      case "2":
        return (
          <div className="absolute right-0 bottom-0 mb-3 mr-3 bg-orange px-6 rounded-r-full rounded-bl-full py-1 text-orangeStrong text-sm font-bold">
            Avanzado
          </div>
        );
      default:
        return null;
    }
  };

  return (
    <div className="flex justify-center mt-5 lg:mt-12 relative">
      <Link
        to={`/recetas/${value.id}`}
        onClick={(e) => dragging && e.preventDefault()}
        className="2xl:w-100 w-90 rounded-xl overflow-hidden shadow-lg bg-white"
      >
        <div className="rounded-bl-large h-56 overflow-hidden relative">
          <img
            src={value.coverIMG}
            alt=""
            className=" w-full h-full object-cover"
          />

          {getLevelTag(value.level)}
        </div>
        <div className="p-3 bg-white">
          <p className=" font-bold text-xl leading-snug h-12 truncate-2-lines">
            {value.name}
          </p>
          <div className="py-2">
            <div className="flex items-center pb-1">
              <div className="flex items-center text-grayText text-sm">
                <IconClock className=" h-4 mr-2" />
                {value.time}
              </div>
              <div className="h-1 w-1 rounded-full bg-blue mx-3"></div>
              <div className="flex items-center text-grayText text-sm">
                <IconCutlery className=" h-4 mr-2" /> {value.portionSize}
              </div>
            </div>
            <div className="">
              <div className="flex items-center text-grayText font-brandom">
                <IconBooklist className=" h-4 mr-2 flex-shrink-0" />
                {value.tip}
              </div>
            </div>
          </div>
        </div>
      </Link>
    </div>
  );
}
