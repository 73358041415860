import React, { useState, useEffect, useRef } from "react";
import CardRecipe from "../../components/CardRecipe";
import arrow from "../../assets/images/arrow-family.png";
import arrow2 from "../../assets/images/arrow-family-2.png";
import Fade from "react-reveal/Fade";
import axios from "axios";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

export default function Familyrecipes({ level }) {
  const [dragging, setDragging] = useState(false);
  const slider = useRef();
  const [recipes, setRecipes] = useState([]);

  const settings = {
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    swipeToSlide: true,
    dots: false,
    beforeChange: () => setDragging(true),
    afterChange: () => setDragging(false),
    responsive: [
      {
        breakpoint: 1023,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 639,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_API_BASE_URL}/recipes?random=true`)
      .then((res) => {
        setRecipes(res.data || []);
      })
      .catch((error) => {
        throw error;
      });
  }, [level]);

  return (
    <div className=" w-full">
      <Fade bottom duration={1000} delay={500} distance={"25%"}>
        <p className=" text-2xl text-grayText text-center font-bold lg:w-full 2xl:w-full">
          Recetas similares
        </p>
      </Fade>

      <Fade bottom duration={1000} delay={500} distance={"25%"}>
        <div className="container flex items-center w-full relative">
          <Slider ref={slider} className="container" {...settings}>
            {recipes.map((val, index) => (
              <div key={index} className="px-2 py-5">
                <CardRecipe value={val} dragging={dragging} />
              </div>
            ))}
          </Slider>

          <div
            onClick={() => slider.current.slickPrev()}
            className="absolute left-0 transform  w-12 lg:w-16 h-12 lg:h-16 z-10"
          >
            <img src={arrow2} alt="" className="h-full cursor-pointer" />
          </div>

          <div
            onClick={() => slider.current.slickNext()}
            className="absolute right-0 transform  w-12 lg:w-16 h-12 lg:h-16 z-10"
          >
            <img src={arrow} alt="" className="h-full cursor-pointer" />
          </div>
        </div>
      </Fade>
    </div>
  );
}
