import React from "react";
import { ReactComponent as Brand } from "../../assets/images/brand-gray.svg";
import { ReactComponent as Facebook } from "../../assets/images/fb.svg";
import { ReactComponent as Instagram } from "../../assets/images/ig.svg";
import { ReactComponent as Aeme } from "../../assets/images/aeme.svg";

export default function Footer() {
  return (
    <footer className=" bg-blue py-5 pt-32 lg:pt-10 px-5 lg:px-0">
      <div className="container lg:flex justify-between py-5">
        <div className="flex items-center justify-center lg:justify-start">
          <div className="py-5">
            <Brand className="object-cover h-20 2xl:h-24" />
          </div>
          <p className=" font-barth text-4xl text-grayText ml-5">Recetario</p>
        </div>

        <div className=" border-b-2 border-grayText my-5 mb-10"></div>

        <div className="lg:flex items-center">
          <nav>
            <ul className=" text-white lg:space-x-10 space-y-4 lg:space-y-0 lg:flex font-brandom font-medium">
              <li>
                <a
                  href="https://supermarino.com/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  INICIO
                </a>
              </li>
              <li>
                <a
                  href="https://supermarino.com/productos/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  PRODUCTOS
                </a>
              </li>
              <li>
                <a
                  href="https://supermarino.com/quienes-somos/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  QUIÉNES SOMOS
                </a>
              </li>
              <li>
                <a
                  href="https://supermarino.com/puntos-de-venta/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  PUNTOS DE VENTA
                </a>
              </li>
              <li>
                <a
                  href="https://supermarino.com/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  RECETAS
                </a>
              </li>
              <li>
                <a
                  href="https://supermarino.com/#contact"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  CONTÁCTENOS
                </a>
              </li>
            </ul>
          </nav>
          <div className="lg:ml-10 my-5 lg:my-0 space-x-3 flex items-center">
            <a
              href="https://www.facebook.com/SuperMarinoSV"
              target="_blank"
              rel="noopener noreferrer"
              className=" text-white"
            >
              <Facebook className="h-6 fill-current" />
            </a>
            <a
              href="https://www.instagram.com/supermarinosv/"
              target="_blank"
              rel="noopener noreferrer"
              className=" text-white"
            >
              <Instagram className="h-6 fill-current" />
            </a>
          </div>
        </div>
      </div>
      <div className="container lg:flex items-center justify-between">
        <p className=" text-grayText text-sm">
          © Super Marino Seafood 2020. Todos los derechos reservados.
        </p>
        <p className=" text-grayText py-3 lg:py-0">
          <a
            href="https://12aeme.studio"
            target="_blank"
            rel="noopener noreferrer"
          >
            <Aeme className=" h-5 fill-current mt-5 lg:mt-0" />
          </a>
        </p>
      </div>
    </footer>
  );
}
