import React, { useState, useEffect } from "react";
import Footer from "../../components/Footer";
import HeaderHome from "./HeaderHome";
import crab from "../../assets/images/crab.png";
import shrimp from "../../assets/images/shrimp.png";
import CardRecipe from "../../components/CardRecipe";
import NewsLetters from "../../components/NewsLetters";
import Fade from "react-reveal/Fade";
import axios from "axios";

export default function Home() {
  const [isLoading, setIsLoading] = useState("");
  const [recipesLevel0, setRecipesLevel0] = useState([]);
  const [recipesLevel1, setRecipesLevel1] = useState([]);
  const [recipesLevel2, setRecipesLevel2] = useState([]);

  useEffect(() => {
    setIsLoading(true);
    axios
      .all([
        axios.get(`${process.env.REACT_APP_API_BASE_URL}/recipes?level=0`),
        axios.get(`${process.env.REACT_APP_API_BASE_URL}/recipes?level=1`),
        axios.get(`${process.env.REACT_APP_API_BASE_URL}/recipes?level=2`),
      ])
      .then(
        axios.spread((res1, res2, res3) => {
          setRecipesLevel0(res1.data || []);
          setRecipesLevel1(res2.data || []);
          setRecipesLevel2(res3.data || []);
          setIsLoading(false);
        })
      )
      .catch((errors) => {
        setIsLoading(false);
        throw errors;
      });
  }, []);

  return (
    <div className="overflow-hidden">
      <HeaderHome />

      <div className="bg-white mt-20">
        <Fade bottom duration={1000} delay={1500}>
          <p className="text-blueText font-bold text-center text-xl lg:text-2xl">
            Recetas para Principiantes
          </p>
          <p className="text-grayText font-medium lg:font-bold font-brandom text-center lg:text-lg px-10">
            Encuentra recetas fáciles y prácticas de preparar en tan solo unos
            minutos.
          </p>
        </Fade>

        <Fade bottom duration={1000} delay={1500}>
          <div className="mb-10 container grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 relative">
            <div className="absolute left-0 bottom-0 w-56 lg:w-90 transform -translate-x-12 lg:-translate-x-64 translate-y-20 lg:translate-y-40">
              <Fade duration={500} delay={500}>
                <img src={crab} alt=" w-full" />
              </Fade>
            </div>
            {!isLoading ? (
              recipesLevel0
                .slice(0, 6)
                .map((val, index) => <CardRecipe value={val} key={index} />)
            ) : (
              <div className=""></div>
            )}
          </div>
        </Fade>

        <div className="lg:hidden flex justify-center pt-10 relative">
          <div className="w-32 border-b-4 border-blue"></div>
        </div>

        <Fade bottom duration={1000} delay={500}>
          <p className="  text-blueText font-bold text-center text-xl lg:text-2xl pt-20 relative">
            Recetas nivel Intermedio
          </p>
          <p className=" text-grayText font-medium lg:font-bold font-brandom text-center lg:text-lg px-10 relative">
            Encuentra recetas nuevas cada semana y aprende cada vez, más
            combinaciones y diferentes formas de preparar platillos.
          </p>
        </Fade>

        <Fade bottom duration={1000} delay={500}>
          <div className="mb-10 container grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 relative">
            <div className="absolute right-0 bottom-0 w-56 lg:w-90 transform translate-x-20 lg:translate-x-64 translate-y-32 lg:-translate-y-24">
              <Fade duration={500} delay={500}>
                <img src={shrimp} alt=" w-full" />
              </Fade>
            </div>
            {!isLoading ? (
              recipesLevel1
                .slice(0, 6)
                .map((val, index) => <CardRecipe value={val} key={index} />)
            ) : (
              <div className=""></div>
            )}
          </div>
        </Fade>

        <div className="lg:hidden flex justify-center pt-10 relative">
          <div className="w-32 border-b-4 border-blue"></div>
        </div>

        <Fade bottom duration={1000} delay={500}>
          <p className="  text-blueText font-bold text-center text-xl lg:text-2xl pt-20 relative">
            Recetas para Expertos
          </p>
          <p className=" text-grayText font-medium lg:font-bold font-brandom text-center lg:text-lg px-10 relative">
            Encuentra recetas de platos exquisitos que harán perfeccionarte aún
            más en la cocina.
          </p>
        </Fade>

        <Fade bottom duration={1000} delay={500}>
          <div className="mb-10 container flex flex-wrap justify-center space-x-16">
            {!isLoading ? (
              recipesLevel2
                .slice(0, 2)
                .map((val, index) => <CardRecipe value={val} key={index} />)
            ) : (
              <div className=""></div>
            )}
          </div>
        </Fade>

        <NewsLetters />

        <Footer />
      </div>
    </div>
  );
}
