import React, { useEffect, useState } from "react";
import splash from "../../assets/images/splash.png";
import { ReactComponent as FindInStore } from "../../assets/images/find-in-store.svg";
import { ReactComponent as IconBooklist } from "../../assets/images/icon-booklist.svg";
import Fade from "react-reveal/Fade";

export default function Ingredients({ insgredients, tip }) {
  const [data, setData] = useState([]);

  useEffect(() => {
    setData(insgredients);
  }, [insgredients]);

  return (
    <div className="my-20 -mx-4 lg:mx-0">
      <Fade bottom duration={1000} delay={500} distance={"25%"}>
        <p className=" font-brandom font-bold text-blueText text-3xl bg-beige lg:bg-white pt-5 lg:pt-0 px-5 lg:px-0">
          Ingredientes
        </p>
      </Fade>

      <Fade bottom duration={1000} delay={500} distance={"25%"}>
        <div className=" pt-10 lg:mt-10 bg-beige px-5 lg:px-20 2xl:px-40 relative py-5">
          <div className="absolute right-0 top-0 lg:bottom-0 transform -translate-y-32 lg:translate-y-10 lg:translate-x-10">
            <img src={splash} alt="" className=" w-32 lg:w-40" />
          </div>

          <div className="lg:hidden">
            {data &&
              data.map((ingredient, index) => (
                <div
                  key={index}
                  className={`border-b border-grayText ${
                    ingredient.specs === "shop"
                      ? "flex items-center justify-between"
                      : ""
                  }`}
                >
                  <p className="font-brandom text-blueText py-2">
                    <b>{ingredient.portion}</b> {ingredient.ingredient}
                  </p>
                  {ingredient.specs === "shop" && (
                    <div
                      target="_blank"
                      rel="noopener noreferrer"
                      className="flex items-center"
                    >
                      <FindInStore className=" h-8 mr-2 -mb-1" />
                    </div>
                  )}
                </div>
              ))}
            <div className="pb-10">
              <p className="font-brandom text-orange py-2">
                *Consulta disponibilidad de estos abarrotes en nuestras tiendas.
              </p>
            </div>
          </div>

          <table className="w-full hidden lg:block table-fixed">
            <thead className=" w-full">
              <tr>
                <td className=" w-1/3 font-medium text-grayText py-4">
                  Cantidad
                </td>
                <td className=" w-1/3 font-medium text-grayText py-4">
                  Ingrediente
                </td>
                <td className=" w-1/3 font-medium text-grayText py-4">
                  Especificaciones
                </td>
              </tr>
            </thead>
            <tbody>
              {data &&
                data.map((ingredient, index) => (
                  <tr key={index} className=" border-t border-grayText ">
                    <td className="font-bold font-brandom text-blueText text-xl py-2">
                      {ingredient.portion}
                    </td>
                    <td className="font-bold font-brandom text-blueText text-xl py-2">
                      <span className=" text-orange">•</span>{" "}
                      {ingredient.ingredient}
                    </td>
                    {ingredient.specs !== "shop" ? (
                      <td className="font-bold font-brandom text-blueText text-xl py-2">
                        {ingredient.specs}
                      </td>
                    ) : (
                      <td className="font-bold font-brandom text-orange uppercase text-lg py-2 flex items-center">
                        <FindInStore className=" h-8 mr-2 -mb-1" />
                        encuentra en tienda
                      </td>
                    )}
                  </tr>
                ))}
              <tr className=" border-t border-grayText ">
                <td className="font-bold font-brandom text-blueText text-xl py-2"></td>
                <td className="font-bold font-brandom text-blueText text-xl py-2"></td>
                <td className="font-brandom text-orange py-2">
                  *Consulta disponibilidad de estos abarrotes en nuestras
                  tiendas.
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </Fade>

      <div className="mt-10 px-5 lg:px-0">
        <Fade bottom duration={1000} delay={500} distance={"25%"}>
          <p className="uppercase text-blueText text-xl font-bold">tip:</p>
          <div className="flex items-center text-grayText text-xl font-brandom mt-5">
            <IconBooklist className=" h-6 mr-2 flex-shrink-0" />
            {tip}
          </div>
        </Fade>
      </div>

      <div className="border-b border-orange mt-10 mx-5 lg:px-0"></div>
    </div>
  );
}
