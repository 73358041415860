import React from "react";
import { ReactComponent as IconBook } from "../assets/images/icon-booklist.svg";
import { Link } from "react-scroll";

export default function ButtonReceiveOffers() {
  return (
    <Link
      to="newsletter"
      spy={true}
      smooth={true}
      duration={500}
      offset={-175}
      className=" shadow-lg border-2 border-orange text-orange flex items-center justify-center px-3 py-2 rounded-lg font-bold text-sm focus:outline-none cursor-pointer"
    >
      <IconBook className="h-6 mr-2" />
      Recibir recetas
    </Link>
  );
}
