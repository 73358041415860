import React, { useState } from "react";
import image04 from "../../assets/images/04.png";
import image05 from "../../assets/images/05.png";
import splash from "../../assets/images/splash.png";
import Fade from "react-reveal/Fade";
import Axios from "axios";
export default function NewsLetters() {
  const [isSuccess, setIsSuccess] = useState(false);
  const [inputError, setInputError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [email, setEmail] = useState("");

  const onSubmitNewsletter = (e) => {
    e.preventDefault();

    if (email.trim() !== "") {
      setInputError(false);
      setIsLoading(true);

      Axios.post(
        "https://us-central1-proyecto-unete.cloudfunctions.net/utils/SMnewsletter",
        {
          email,
        }
      )
        .then((response) => {
          if (response.data.error) {
            setIsLoading(false);
            return;
          }

          if (response.status === 200) {
            setIsSuccess(true);
          }
          setIsLoading(false);
        })
        .catch((error) => {
          alert("Algo salió mal, inténtalo mas tarde!");
          setIsLoading(false);
          throw error;
        });
    } else {
      setInputError(true);
    }
  };

  return (
    <div
      name="newsletter"
      className=" bg-lightGray flex flex-col justify-center items-center py-24 container mt-56 lg:my-32 relative"
    >
      <div className="absolute right-0 bottom-0 lg:top-0 lg:-mr-5 -mt-10 transform lg:transform-none -translate-y-24">
        <img src={splash} alt="" className=" w-32 lg:w-40" />
      </div>
      <div className="absolute lg:block flex justify-center top-0 lg:top-auto transform -translate-y-32 lg:translate-y-0 lg:-translate-x-20 lg:left-0">
        <img src={image04} alt="" className=" w-64" />
      </div>
      <div className="absolute lg:block flex justify-center bottom-0 lg:right-0 -mb-20 transform lg:transform-none translate-y-10">
        <img src={image05} alt="" className=" w-64 lg:mr-10" />
      </div>

      <Fade bottom duration={1000} delay={500} distance={"25%"}>
        <div className="mt-10 lg:mt-0 relative">
          <p className=" text-blueText text-lg lg:text-2xl font-bold text-center">
            Deliciosas recetas cada semana
          </p>
          <p className=" text-grayText font-bold font-brandom text-center lg:text-xl px-5 lg:px-0">
            Subscríbete a nuestro recetario y recibe cada semana las mejores
            recetas que hemos preparado especialmente para tí.
          </p>
        </div>
        <form
          onSubmit={(e) => onSubmitNewsletter(e)}
          className="mt-12 w-full flex flex-col lg:flex-row justify-center items-center mb-20 lg:mb-0 relative"
        >
          <input
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            className={` placeholder-grayText text-blueText py-3 px-3 rounded-lg shadow-lg lg:mr-4 lg:w-90 w-full focus:outline-none block ${
              inputError && "border border-red"
            }`}
            placeholder="Correo Eléctronico"
          />

          <button
            disabled={isLoading || isSuccess}
            className={`py-3 px-4 text-white font-bold rounded-lg shadow-lg text-sm focus:outline-none w-full lg:w-auto mt-5 lg:mt-0 block ${
              isSuccess ? " bg-greenStrong cursor-auto" : "bg-orange"
            }`}
          >
            {isLoading && "Cargando"}
            {!isLoading && isSuccess && "Completado"}
            {!isLoading && !isSuccess && "Subscribirme"}
          </button>
        </form>
      </Fade>
    </div>
  );
}
