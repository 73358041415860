import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { ReactComponent as Brand } from "../../assets/images/brand.svg";
import { ReactComponent as Facebook } from "../../assets/images/fb.svg";
import { ReactComponent as Instagram } from "../../assets/images/ig.svg";
import { ReactComponent as IconSearch } from "../../assets/images/icon-search.svg";
import { ReactComponent as Arrow } from "../../assets/images/arrow-process.svg";
import ButtonReceiveOffers from "../ButtonReceiveOffers";
import CardRecipe from "../CardRecipe";
import axios from "axios";

export default function Navbar({ profile, showSearchRequest, searchText }) {
  const [searchValue, setSearchValue] = useState("");
  const [isLoading, setIsLoading] = useState("");
  const [openSearchMobile, setOpenSearchMobile] = useState(false);
  const [results, setResults] = useState([]);

  useEffect(() => {
    if (searchText) {
      setSearchValue(searchText);
      setOpenSearchMobile(true);
    }
  }, [searchText]);

  useEffect(() => {
    setIsLoading(true);
    const delayDebounceFn = setTimeout(() => {
      if (searchValue.trim() !== "") {
        axios
          .get(
            `${process.env.REACT_APP_API_BASE_URL}/recipes?search=${searchValue}`
          )
          .then((res) => {
            setResults(res.data || []);
            setIsLoading(false);
          })
          .catch((error) => {
            setIsLoading(false);
            throw error;
          });
      }
    }, 300);

    return () => clearTimeout(delayDebounceFn);
  }, [searchValue]);

  useEffect(() => {
    if (searchValue === "") {
      setOpenSearchMobile(false);
    }
  }, [searchValue]);

  return (
    <div
      className={`flex flex-col max-h-screen fixed w-full bg-white  ${
        searchValue ? "" : ""
      } ${openSearchMobile ? "z-100" : "z-50"}`}
    >
      <div
        className={`bg-white w-full flex ${
          openSearchMobile ? "z-100" : "z-50"
        } ${searchValue ? " h-24 lg:h-32" : "h-24"}`}
      >
        <div
          className={`container flex items-center ${
            !profile ? "justify-center lg:justify-between" : "justify-between"
          }`}
        >
          {!searchValue ? (
            <div
              className={`flex items-center transition-all duration-300 ease-in-out`}
            >
              <Link to="/" className="block py-5">
                <Brand className="object-cover h-20 " />
              </Link>
              <p className=" font-barth text-4xl text-blue ml-5 lg:ml-10">
                Recetario
              </p>
            </div>
          ) : (
            <div
              onClick={() => {
                setSearchValue("");
                setOpenSearchMobile(false);
              }}
              className="flex items-center text-orange cursor-pointer"
            >
              <Arrow className="w-8 transform rotate-180 mr-4" /> Regresar
            </div>
          )}

          <div
            className={`transition duration-300 ease-in-out ${
              profile ? "" : "hidden lg:block"
            } ${
              profile || showSearchRequest || searchValue
                ? "opacity-100"
                : "opacity-0"
            }`}
          >
            <div className="hidden lg:block">
              <div
                className={`flex items-center bg-white rounded-full overflow-hidden px-4 transition-all duration-300 ease-in-out ${
                  searchValue ? " lg:w-100" : "lg:w-64 "
                }`}
              >
                <span onClick={() => setOpenSearchMobile(true)}>
                  <IconSearch className="h-5 lg:mr-3" />
                </span>
                <input
                  type="search"
                  onChange={(e) => setSearchValue(e.target.value)}
                  value={searchValue}
                  className="appearance-none focus:outline-none font-montserrat placeholder-grayText font-medium text-blueText w-full py-3 hidden lg:inline text-xl"
                  placeholder="Buscar recetas..."
                />
              </div>
              <div
                className={`border-t-2 border-blue transition-all duration-500 ease-in-out px-4 ${
                  searchValue ? " opacity-100 w-100" : " opacity-0"
                }`}
              ></div>

              <div
                className={`mt-2 items-center justify-center transition-all duration-200 ease-in-out hidden ${
                  searchValue ? "flex opacity-100" : "hidden opacity-0"
                }`}
              >
                <span className=" text-blueText font-medium mr-2 lg:mr-5">
                  Filtrar:
                </span>
                <div className="inline-block border-2 border-blueText text-blueText mr-2 lg:mr-5 rounded-full px-3 py-2 cursor-pointer text-sm">
                  Camarones
                </div>
                <div className=" inline-block border-2 border-blueText text-blueText mr-2 lg:mr-5 rounded-full px-3 py-2 cursor-pointer text-sm">
                  Pescado
                </div>
              </div>
            </div>

            <div className="lg:hidden relative bg-white z-100">
              {!openSearchMobile ? (
                <span onClick={() => setOpenSearchMobile(true)}>
                  <IconSearch className="h-5 lg:mr-3" />
                </span>
              ) : (
                <span
                  onClick={() => {
                    setOpenSearchMobile(false);
                    setSearchValue("");
                  }}
                  className="text-blueText font-medium"
                >
                  Cerrar
                </span>
              )}
            </div>
          </div>

          <div
            className={`items-center hidden lg:flex transition duration-300 ease-in-out ${
              searchValue ? " opacity-0" : " opacity-100"
            }`}
          >
            <ButtonReceiveOffers />
            {!profile && (
              <div className="ml-10 space-x-3 flex items-center">
                <a
                  href="https://www.facebook.com/SuperMarinoSV"
                  target="_blank"
                  rel="noopener noreferrer"
                  className=" text-blue"
                >
                  <Facebook className="h-6 fill-current" />
                </a>
                <a
                  href="https://www.instagram.com/supermarinosv/"
                  target="_blank"
                  rel="noopener noreferrer"
                  className=" text-blue"
                >
                  <Instagram className="h-6 fill-current" />
                </a>
              </div>
            )}
          </div>
        </div>
      </div>

      {openSearchMobile && (
        <div className="lg:hidden bg-white z-50 px-5 relative">
          <div
            className={`flex items-center bg-white overflow-hidden px-4 transition-all duration-300 ease-in-out`}
          >
            <span>
              <IconSearch className="h-5 mr-3" />
            </span>
            <input
              type="search"
              onChange={(e) => setSearchValue(e.target.value)}
              value={searchValue}
              className="appearance-none focus:outline-none font-montserrat placeholder-grayText font-medium text-blueText w-full py-3 inline text-xl"
              placeholder="Buscar recetas..."
            />
          </div>

          <div
            className={`border-t-2 border-blue transition-all duration-500 ease-in-out px-4 mb-2`}
          ></div>

          <div
            className={`mt-2 items-center justify-center transition-all duration-200 ease-in-out mb-3 hidden ${
              searchValue ? "flex opacity-100" : "hidden opacity-0"
            }`}
          >
            <span className=" text-blueText font-medium mr-2 lg:mr-5">
              Filtrar:
            </span>
            <div className="inline-block border-2 border-blueText text-blueText mr-2 lg:mr-5 rounded-full px-3 py-2 cursor-pointer text-sm">
              Camarones
            </div>
            <div className=" inline-block border-2 border-blueText text-blueText mr-2 lg:mr-5 rounded-full px-3 py-2 cursor-pointer text-sm">
              Pescado
            </div>
          </div>
        </div>
      )}

      {searchValue && (
        <div className="inset-0 bg-blue opacity-50 z-10 fixed"></div>
      )}

      <div
        className={`bg-white z-50 duration-700 ease-in-out transition-all transform flex-0 w-full overflow-y-auto ${
          searchValue && !isLoading
            ? "translate-y-0"
            : "translate-y-full hidden"
        }`}
      >
        {results.length > 0 && (
          <div className="relative">
            <p className="container text-grayText font-medium">
              {results.length} Resultados
            </p>
            <div className="container grid grid-cols-1 lg:grid-cols-3 pb-10">
              {results.map((val, index) => (
                <CardRecipe value={val} key={index} />
              ))}
            </div>
          </div>
        )}

        {results.length === 0 && (
          <div className="relative h-screen">
            <div className="container py-20 h-full">
              <p className=" text-center text-blueText font-bold text-3xl">
                No se encontraron coincidencias
              </p>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
