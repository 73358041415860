import React, { useEffect, useState } from "react";
import share from "../../assets/images/share-d.svg";
import fb from "../../assets/images/fb-d.svg";
import tw from "../../assets/images/tw-d.svg";
import lin from "../../assets/images/in-d.svg";
import Fade from "react-reveal/Fade";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { ReactComponent as Arrow } from "../../assets/images/arrow-process.svg";

export default function Process({ process }) {
  const [data, setData] = useState([]);

  useEffect(() => {
    setData(process);
  }, [process]);

  return (
    <div className="mb-20">
      <Fade bottom duration={1000} delay={500} distance={"25%"}>
        <p className=" font-brandom font-bold text-blueText text-3xl">
          Procedimiento
        </p>
      </Fade>
      <Fade bottom duration={1000} delay={500} distance={"25%"}>
        <div className="grid lg:grid-cols-3">
          {data &&
            data.map((step, index) => (
              <div key={index} className="lg:px-16 my-5 lg:my-10 relative">
                <p className="font-brandom font-bold text-orange text-2xl">
                  {step.order}
                </p>
                <p className="text-blueText mt-3">{step.description}</p>

                {(index + 1) % 3 !== 0 && data.length - 1 !== index && (
                  <div className="absolute inset-y-0 lg:flex items-center right-0 transform translate-x-1/2 hidden">
                    <Arrow className=" w-8" />
                  </div>
                )}
              </div>
            ))}
        </div>
      </Fade>
      <div className="border-b border-grayText mt-12"></div>

      <Fade bottom duration={1000} delay={500} distance={"25%"}>
        <div className="hidden lg:flex justify-end">
          <div className="flex items-center mt-4">
            <span className=" text-grayText mr-4">Compartir Receta</span>
            <ul className=" flex space-x-3">
              <li>
                <a
                  href={`http://www.facebook.com/share.php?u=${window.location}`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img src={fb} alt="" className="h-10" />
                </a>
              </li>
              <li>
                <a
                  href={`https://twitter.com/intent/tweet?text=${window.location}`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img src={tw} alt="" className="h-10" />
                </a>
              </li>
              <li>
                <a
                  href={`https://www.linkedin.com/shareArticle?mini=true&url=${window.location}&title=&summary=&source=`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img src={lin} alt="" className="h-10" />
                </a>
              </li>
              <li>
                <CopyToClipboard
                  text={window.location}
                  onCopy={() => alert("Enlace copiado al portapapeles")}
                >
                  <span>
                    <img src={share} alt="" className="h-10 cursor-pointer" />
                  </span>
                </CopyToClipboard>
              </li>
            </ul>
          </div>
        </div>
      </Fade>
    </div>
  );
}
